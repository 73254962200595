import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import Button from '../../components/Button';
import ScoreVisualisation from '../../components/ScoreVisualisation';
import BackHeader from '../../components/BackHeader';
import Card from '../../components/Card';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_INCOME_SCORE, GET_SPENDING_SCORE } from '../../graph/clarity';
import { GET_CUSTOM_JOBS } from '../../graph/jobs';

import getUniqueIndustriesFromJobs from '../../utils/getUniqueIndustriesFromJobs';

const ResultsAbout = ({ type }) => {
  useTrackPage('/results/net-hourly-wage');

  const {
    data: { jobs },
  } = useQuery(GET_CUSTOM_JOBS, {
    variables: {
      isCommonToUsers: false,
    },
    fetchPolicy: 'cache-first',
  });

  const uniqueIndustryList = getUniqueIndustriesFromJobs(jobs);

  const { data: incomeScoreData, loading: loadingIncome } = useQuery(
    GET_INCOME_SCORE,
    {
      variables: {
        industry: uniqueIndustryList[0]?.id || 'deliveryGeneral',
      },
      skip: type !== 'income',
    },
  );

  const { data: spendingScoreData, loading: loadingSpending } = useQuery(
    GET_SPENDING_SCORE,
    {
      variables: {
        industry: uniqueIndustryList[0]?.id || 'deliveryGeneral',
      },
      skip: type !== 'spendingHabits',
    },
  );

  const score =
    incomeScoreData?.score?.incomeScore ||
    spendingScoreData?.score?.spendingScore;
  const potentialScore =
    incomeScoreData?.score?.potentialIncomeScore ||
    spendingScoreData?.score?.potentialSpendingScore;

  const normalisedScore = Math.min(score, 10);

  const getScoreText = () => {
    if (!score) return {};

    if (normalisedScore < 4) {
      return {
        title: 'Not feeling great 😓',
        text: 'Your financial health is suffering a bit – but don’t panic. There’s lots we can work on to help you improve. Get started by clicking the link below.',
      };
    }

    if (normalisedScore < 6) {
      return {
        title: 'Feeling ok 🥱',
        text: 'You’ve got some good habits, but your financial health could still do with a bit of a boost. Get to a better place by clicking the link below.',
      };
    }

    if (normalisedScore < 8) {
      return {
        title: 'Feeling pretty good 😌',
        text: 'Great work! Your financial health is in a good place. There are just a few small changes you could make to improve – click the link below to learn more.',
      };
    }

    return {
      title: 'Feeling great! 🙌',
      text: 'Impressive stuff! Your financial health is in truly great shape. Keep it that way by checking in with Clarity regularly. ',
    };
  };

  return (
    <Layout isFetching={loadingIncome || loadingSpending} scrollable>
      <BackHeader title="Back" showSave />

      <ScoreVisualisation score={score} potentialScore={potentialScore} />

      <Card center {...getScoreText()} />

      {type === 'income' && (
        <div style={{ margin: '20px 0' }}>
          <Button alt to="/results/improve-my-wellness">
            How can I improve?
          </Button>
        </div>
      )}

      {type === 'spendingHabits' && (
        <div style={{ margin: '20px 0' }}>
          <Button alt to="/results/improve-my-score">
            How can I improve?
          </Button>
        </div>
      )}

      <div>
        <Button to="/results">Back to results</Button>
      </div>
    </Layout>
  );
};

ResultsAbout.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ResultsAbout;
